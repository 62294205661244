<template>
  <div id="app" class="mt-4">
    <div class="container mx-auto">
      <div class="mb-4 text-center">
        <h1 class="text-4xl text-blue-500">Broneeri aeg drooni remondiks</h1>
        <h2 class="text-2xl text-gray-400">www.droonimaailm.ee</h2>
      </div>

      <BookingForm/>
    </div>
  </div>
</template>

<script>
import BookingForm from './components/BookingForm.vue'

export default {
  name: 'App',
  components: {
    BookingForm
  }
}
</script>
