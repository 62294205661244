<template>
  <div class="pt-2">

    <!-- Error -->
    <div class="bg-red-500 text-white py-2 px-4 mb-4 rounded" v-if="error">Palun veenduge, et kõik kohustuslikud väljad on korrektselt täidetud!</div>

    <transition name="fade" mode="out-in">
      <!-- STEP 0 - Customer details -->
      <div key="0" v-if="step === 0">
        <div class="grid grid-cols-2 gap-4">
          <!-- Name -->
          <div>
            <h3>Teie nimi <span class="text-red-500">*</span></h3>
            <input type="text" class="w-full border border-gray-500 p-2" placeholder="Ees- ja perekonna nimi" v-model="customer.name">
            <div class="text-red-400" v-if="!$v.customer.name.minLength">Palun sisestage enda nimi</div>
          </div>

          <!-- Phone -->
          <div>
            <h3>Kontakttelefon <span class="text-red-500">*</span></h3>
            <input type="tel" class="w-full border border-gray-500 p-2" placeholder="näiteks 37255123456" v-model="customer.phone">
            <div class="text-red-400" v-if="!$v.customer.phone.minLength">Palun sisestage enda kontakttelefon</div>
            <div class="text-red-400" v-if="$v.customer.phone.minLength && !$v.customer.phone.numeric">Palun kasutage ainult numbreid</div>
          </div>

          <!-- E-mail -->
          <div>
            <h3>E-posti aadress <span class="text-red-500">*</span></h3>
            <input type="email" class="w-full border border-gray-500 p-2" placeholder="email@näide.com" v-model="customer.email">
            <div class="text-red-400" v-if="!$v.customer.email.email">Palun sisestage korrektne e-posti aadress</div>
          </div>

          <!-- Company -->
          <div>
            <h3>Ettevõtte nimi</h3>
            <input type="text" class="w-full border border-gray-500 p-2" placeholder="Kui soovite arvet ettevõttele" v-model="customer.company">
          </div>

          <!-- Proceed to step 1 -->
          <div class="col-span-2">
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-4" type="button" @click="navigate(1)">Jätka drooni andmete sisestamisega</button>
          </div>
        </div>
      </div>

      <!-- STEP 1 - Drone details -->
      <div key="1" v-if="step === 1">
        <div class="grid grid-cols-3 gap-4">
          <!-- Return to step 0 -->
          <div class="col-span-3">
            <button class="bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded w-full" type="button" @click="navigate(0)">Tagasi kliendiandmeid täpsustama</button>
          </div>

          <!-- Model -->
          <div>
            <h3>Drooni mudel:</h3>
            <select class="w-full border border-gray-500 p-2" v-model="drone.model">
              <option value="Muu mudel">Muu mudel</option>
              <option v-for="model in options.products" v-bind:key="model" v-bind:value="model">{{ model }}</option>
            </select>
          </div>

          <!-- Serial -->
          <div>
            <h3>Drooni seerianumber:</h3>
            <input type="tel" class="w-full border border-gray-500 p-2" v-model="drone.serial">
          </div>

          <!-- Moisture -->
          <div>
            <h3>&nbsp;</h3>
            <label class="inline-flex items-center">
              <input type="checkbox" class="form-checkbox" v-model="drone.moisture">
              <span class="ml-2 text-xl">Niiskuskahjustus</span>
            </label>
          </div>

          <!-- Description -->
          <div class="col-span-3">
            <h3>Probleemi kirjeldus:</h3>
            <textarea rows="4" class="w-full border border-gray-500 p-2" v-model="drone.description" placeholder="Palun kirjeldage probleemi või vigastust lähemalt"></textarea>
          </div>

          <!-- Insurance claim -->
          <div class="col-span-3">
            <label class="inline-flex items-center">
              <input type="checkbox" class="form-checkbox" v-model="drone.claim">
              <span class="ml-2 text-xl">Soovin vea defekteerimise akti kindlustusele esitamiseks</span>
            </label>
          </div>

          <!-- Proceed to step 2 -->
          <div class="col-span-3">
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full" type="button" @click="navigate(2)">Liigu edasi transpordiviisi valima</button>
          </div>
        </div>
      </div>

      <!-- STEP 2 - Delivery details -->
      <div key="2" v-if="step === 2">
        <div class="grid grid-cols-3 gap-4">
          <!-- Return to step 1 -->
          <div class="col-span-3">
            <button class="bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded w-full" type="button" @click="navigate(1)">Tagasi drooni andmeid täpsustama</button>
          </div>

          <!-- Delivery method -->
          <div class="col-span-3">
            <h3>Kuidas soovid drooni meieni toimetada <span class="text-red-500">*</span></h3>
          </div>

          <!-- Delivery methods -->
          <div class="col-span-3" v-for="option in options.delivery" v-bind:key="option.code">
            <label class="inline-flex items-center">
              <input type="radio" class="form-checkbox" v-bind:value="option.code" v-model="delivery.method">
              <span class="ml-2">{{ option.title }}</span>
            </label>
          </div>

          <!-- Proceed to confirmation -->
          <div class="col-span-3">
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full" type="button" @click="navigate(3)">Edasi</button>
          </div>
        </div>
      </div>

      <!-- STEP 3 - Booking confirmation -->
      <div key="3" v-if="step === 3">
        <div class="grid grid-cols-2 gap-4">
          <!-- Return to step 2 -->
          <div class="col-span-2">
            <button class="bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded w-full" type="button" @click="navigate(2)">Tagasi tarneviisi valima</button>
          </div>

          <!-- Title -->
          <div class="col-span-2">
            <h3>Palun kontrolli sisestatud andmeid ja kui kõik sobib kinnita broneering</h3>
          </div>

          <!-- Name -->
          <div>
            <h3 class="font-bold">Teie nimi:</h3>
            {{ customer.name }}
          </div>

          <!-- Phone -->
          <div>
            <h3 class="font-bold">Kontakttelefon:</h3>
            {{ customer.phone }}
          </div>

          <!-- E-mail -->
          <div>
            <h3 class="font-bold">E-posti aadress:</h3>
            {{ customer.email }}
          </div>

          <!-- Company -->
          <div>
            <h3 class="font-bold">Ettevõtte nimi:</h3>
            {{ customerCompany }}
          </div>

          <!-- Drone model -->
          <div>
            <h3 class="font-bold">Drooni mudel:</h3>
            {{ drone.model }}
          </div>

          <!-- Drone serial -->
          <div>
            <h3 class="font-bold">Drooni seerianumber:</h3>
            {{ droneSerial }}
          </div>

          <!-- Moisture -->
          <div>
            <h3 class="font-bold">Niiskuskahjustus:</h3>
            {{ moistureDamage }}
          </div>

          <!-- Insurance claim -->
          <div>
            <h3 class="font-bold">Vea defekteerimise akt kindlustusele:</h3>
            {{ insuranceClaim }}
          </div>

          <!-- Description -->
          <div class="col-span-2">
            <h3 class="font-bold">Probleemi kirjeldus:</h3>
            {{ droneDescription }}
          </div>

          <!-- Delivery -->
          <div class="col-span-2">
            <h3 class="font-bold">Tarneviis:</h3>
            {{ deliveryMethod }}
          </div>

          <!-- Proceed to confirmation -->
          <div class="col-span-2">
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full" type="button" :disabled="disableConfirm" @click="confirm()">Kinnitan broneeringu</button>
          </div>
        </div>
      </div>

      <!-- STEP 4 - Booking confirmation -->
      <div key="4" v-if="step === 4">
        <div class="bg-blue-500 text-white px-4 py-2 rounded">
          <h1 class="text-3xl">Päring on esitatud!</h1>
          Saatsime broneeringu kinnituse Teie e-posti aadressile ning võtame Teiega peatselt ühendust.
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
import axios from 'axios'
import { required, minLength, email, numeric } from 'vuelidate/lib/validators'

export default {
  data () {
    return {
      step: 0,
      steps: ['customer', 'drone', 'delivery', 'confirmation', 'finish'],
      customer: { name: '', email: '', phone: '', company: '' },
      drone: { model: 'Muu mudel', serial: '', moisture: false, description: '', claim: false },
      delivery: { method: 'self_delivery' },
      options: {
        // TODO: PRODUCTS SHOULD BE POPULATED FROM API!
        products: [
          'DJI Tello', 'DJI Phantom 3 Standard', 'DJI Phantom 3 Advanced', 'DJI Phantom 3 Professional', 'DJI Phantom 4', 'DJI Phantom 4 Advanced', 'DJI Phantom 4 Professional', 'DJI Mavic Mini', 'DJI Mavic Mini 2', 'DJI Mavic Air', 'DJI Mavic Air 2', 'DJI Mavic Air 2S', 'DJI Mavic Air 2 Pro', 'DJI Mavic Air 2 Zoom', 'DJI Mavic Pro', 'DJI Mavic 2 Enterprise Zoom', 'DJI Mavic 2 Enterprise Advanced', 'DJI Mavic 2 Enterprise Dual', 'DJI Mavic 2 Zoom', 'DJI Mavic 2 Pro', 'DJI Mavic 3', 'DJI FPV', 'DJI Inspire 1 + X3', 'DJI Inspire 1 + X5', 'DJI Inspire 2 + X5', 'DJI Inspire 2 + X7', 'DJI Matrice 100', 'DJI Matrice 200', 'DJI Matrice 210', 'DJI Matrice 300', 'DJI Matrice 600'
        ],
        delivery: [
          { code: 'self_delivery', title: 'Soovin drooni ise Droonimaailma tuua (Aadress: Vabaduse 7, Kohila 79806 Droonimaailma tehniline ja treening keskus)' },
          { code: 'prepared_label', title: 'Soovin drooni saatmiseks eelvormistatud pakisilti (TASUTA)' },
          { code: 'self_posted', title: 'Soovin drooni ise postitada (saadame sulle vajaliku info kuidas seda teha)' }
        ]
      },
      disableConfirm: false,
      error: false
    }
  },
  validations: {
    customer: {
      name: { required, minLength: minLength(4) },
      phone: { numeric, minLength: minLength(3), required },
      email: { email }
    },
  },
  computed: {
    moistureDamage: function () {
      return (this.drone.moisture ? 'JAH' : 'EI')
    },
    insuranceClaim: function () {
      return (this.drone.claim ? 'JAH' : 'EI')
    },
    customerCompany: function () {
      return (this.customer.company ? this.customer.company : '-')
    },
    droneSerial: function () {
      return (this.drone.serial ? this.drone.serial : '-')
    },
    droneDescription: function () {
      return (this.drone.description ? this.drone.description : '-')
    },
    deliveryMethod: function () {
      const method = this.options.delivery.filter(obj => { return obj.code === this.delivery.method })
      return method[0].title
    }
  },
  mounted () {},
  methods: {
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty
      }
    },
    navigate (step) {
      // Validate form if proceeding but not if returning
      if (step > this.step) {
        // const stepName = this.steps[step - 1]

        this.$v.$touch()
        if (this.$v.$invalid) {
          this.error = true
          // console.log(stepName + ' error')

        } else {
          // console.log(stepName + ' ok vist')
          this.error = false
          this.step = step

        }
      } else {
        this.step = step
      }
    },
    confirm () {
      this.disableConfirm = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.error = true
        // console.log('error')

      } else {
        // console.log('ok vist')
        this.error = false

        // Post to backend
        const _this = this
        axios.post('https://droonimaailm.pilv.app:5000/addBooking', {
          name: this.customer.name,
          email: this.customer.email,
          phone: this.customer.phone,
          company: this.customerCompany,
          model: this.drone.model,
          serial: this.droneSerial,
          moisture: this.moistureDamage,
          insurance: this.insuranceClaim,
          description: this.droneDescription,
          delivery: this.deliveryMethod
        })
            .then(function (response) {
              if(response.data.status === 1) {
                _this.navigate(4)
              } else {
                _this.error = true
              }

            })
            .catch(function (error) {
              console.log(error)
              _this.disableConfirm = false
            })
      }

    }
  }
}
</script>
